<template>
  <v-row justify="start">
    <v-dialog v-model="dialogChangePhoto" persistent max-width="530px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="min-height: 300px">
          <div class="pa-3">
            <div class="px-5">
              <v-row>
              <v-col class="py-2" cols="12">
                <label for="" class="color_txt"><b>Pilih Foto</b></label>
                <br><br>
                <v-file-input
                  v-model="photoProfile"
                  dense
                  ref="field1"
                  class="mt-3"
                  color="#00b4cc"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="Pilih Foto"
                  prepend-icon="mdi-camera"
                  label="Choose File (.jpg, .png, .jpeg, or .gif, max 10mb)"
                ></v-file-input>
              </v-col>
             </v-row>
             <br><br>
             <div class="d-flex justify-center mt-1">
                <v-btn dark depressed color="#00b4cc" class="text-capitalize" style="border-radius: 25px" @click="changeProfile()">
                    SIMPAN
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import { mapState } from "vuex";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

export default {
  name: "dialogChangePht",
  props: ["dialogChangePhoto"],

  computed: {
  },
  data() {
    return {
      photoProfile: null
    };
  },
  methods: {
    changeProfile(){
      // let data = {
      //   image: this.photoProfile,
      //   id: Cookies.get('id')
      // };
      let data = new FormData();
      data.append('file', this.photoProfile)
      data.append('id', Cookies.get('id'))
      this.$store
        .dispatch("user/changeProfile",data)
        .then((data) => {
          this.Swal("success", "Ubah Photo Profile Berhasil");
          this.$emit("close");
          this.$emit("changePhotoEmit");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>