<template>
  <div class="padd_content">
    <div class="d-flex pa-2 mb-3 align-center">
      <blue-circle class="mr-2" />
      <h3 class="color_default">Profile</h3>
    </div>
    <div>
      <v-card class="custom_card mb-5 mx-2">
        <div class="head_content bg_default d-flex align-end">
          <div style="width: 100%">
            <div class="strip bg_scn"></div>
            <div class="strip bg_trth"></div>
          </div>
        </div>
        <div class="mb-2">
          <div class="d-flex justify-center">
            <div style="position: relative">
              <div class="frame_img d-flex align-center justify-center">
                <img :src="dummy" class="pp" alt="" />
              </div>
              <div class="btn_cam d-flex align-center justify-center" @click="dialogChangePhoto=true">
                <v-icon dark>mdi-camera-plus</v-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="px-3 pb-10">
          <div>
            <h2 class="text-center color_default mb-1">
              {{ person.full_name }}
            </h2>
            <p class="text-center color_txt">{{ person.access_right }}</p>
          </div>
          <div class="px-2 mt-5">
            <div class="line_blue"></div>
            <div class="temp_tx">
              <b class="color_default">Penanggung Jawab</b>
            </div>
          </div>
          <profile-admin
            v-if="person.access_right == 'Administrator'"
            v-bind:person="person"
          />
          <profile-fasyankes
            v-if="person.access_right == 'Fasyankes'"
            v-bind:person="person"
          />
          <profile-kemenkes
            v-if="person.access_right == 'Kemenkes'"
            v-bind:person="person"
          />
          <profile-eksternal
            v-if="person.access_right == 'Eksternal'"
            v-bind:person="person"
           />
          <profile-dinkes-prov
            v-if="person.access_right == 'Dinkes Provinsi'"
            v-bind:person="person"
          />
          <profile-dinkes-kab
            v-if="person.access_right == 'Dinkes Kota'"
            v-bind:person="person"
          />
          <div class="d-flex justify-end">
            <v-btn
              text
              small
              @click="dialogChangePwd = true"
              class="text-capitalize small_txt color_txt"
              >Ubah Password</v-btn
            >
          </div>
          <change-password
            :dataUser="this.person"
            :dialogChangePwd="dialogChangePwd"
            @close="dialogChangePwd = false"
          />
          <change-photo
            :dialogChangePhoto="dialogChangePhoto"
            @close="dialogChangePhoto = false"
            @changePhotoEmit="getPhotoProfile()"
          />
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import BlueCircle from "../../components/General/BlueCircle";
import ChangePassword from "../../components/Pengguna/Update/changePassword.vue";
import ChangePhoto from "../../components/Pengguna/Update/changePhoto.vue";
import ProfileAdmin from "./Fasyankes/profileAdmin.vue";
import ProfileDinkesKab from "./Fasyankes/profileDinkesKab.vue";
import ProfileDinkesProv from "./Fasyankes/profileDinkesProv.vue";
import ProfileFasyankes from "./Fasyankes/profileFasyankes.vue";
import ProfileKemenkes from "./Fasyankes/profileKemenkes.vue";
import ProfileEksternal from "./Fasyankes/profileEksternal.vue";

export default {
  name: "Profile",
  data() {
    return {
      field: 0,
      change: false,
      loading: false,
      dialogChangePwd: false,
      dialogChangePhoto: false,
      dataUser: null
    };
  },
  computed: {
    ...mapState({
      person: (state) => state.user.profile,
      dummy: (state) => state.imgDummy,
    }),
  },
  mounted(){
    this.photoProfile=this.dummy
    this.getPhotoProfile()
  },
  components: {
    BlueCircle,
    ProfileFasyankes,
    ProfileKemenkes,
    ProfileDinkesProv,
    ProfileDinkesKab,
    ProfileAdmin,
    ProfileEksternal,
    ChangePassword,
    ChangePhoto,
  },
  methods: {
    switchElmt(idx) {
      this.field = idx;
      this.change = true;
      setTimeout(() => {
        this.$refs[`field${idx}`].focus();
      }, 100);
    },
    getPhotoProfile(){
      this.$store
        .dispatch("getPhotoProfile")
        .then((data) => {})
    },
    updateData() {
      this.loading = true;
      this.$store
        .dispatch("user/updateUser", this.person)
        .then((data) => {
          this.field = 0;
          this.change = false;
          this.loading = false;
          this.Swal("success", data.message);
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    updatePassword() {
      this.dataUser = this.person;
      this.dialogChangePwd = true;
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.head_content {
  height: 170px;
}
.strip {
  height: 40px;
}
.frame_img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  /* background: #9fe3ec; */
  background: #9fe3eca7;
  margin-top: -100px;

  /* opacity: 0.9; */
}
.pp {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}
.btn_cam {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #9fe3eca7;
  cursor: pointer;
}
.form_txt {
  border-bottom: 1px #f2f2f2 solid;
}
.action_btn {
  margin-top: -40px;
}
.role {
  background: #f2f2f2;
}
.small-txt {
  font-size: small !important;
}
.temp_tx {
  background: #fff;
  width: max-content;
  padding: 5px;
  margin-top: -18px;
}
.line_blue {
  width: 100%;
  height: 2px;
  background: #00b4cc;
}
</style>