<template>
    <div class="pa-3">
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col class="py-2" cols="12">
              <label for="" class="color_txt">Nama Instansi</label>
              <div class="pa-2 color_txt form_txt" v-if="field != 1">
                {{ person.nama_instansi ? person.nama_instansi : "-" }}
              </div>
              <v-text-field
                v-if="change && field == 1"
                :loading="loading && field == 1"
                @keyup.enter="updateData(1)"
                hide-details
                v-model="person.nama_instansi"
                dense
                ref="field1"
                class="mt-3"
                color="#00b4cc"
              ></v-text-field>
              <div class="d-flex justify-end action_btn">
                <v-btn icon v-if="field != 1" small @click="switchElmt(1)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn icon v-if="change && field == 1" small @click="updateData"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col class="py-2" cols="12">
              <label for="" class="color_txt">Nama</label>
              <div class="pa-2 color_txt form_txt" v-if="field != 3">
                {{ person.full_name ? person.full_name : "-" }}
              </div>
              <v-text-field
                v-if="change && field == 3"
                :loading="loading && field == 3"
                @keyup.enter="updateData(3)"
                hide-details
                v-model="person.full_name"
                ref="field3"
                dense
                class="mt-3"
                color="#00b4cc"
              ></v-text-field>
              <div class="d-flex justify-end action_btn">
                <v-btn icon v-if="field != 3" small @click="switchElmt(3)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn icon v-if="change && field == 3" small @click="updateData"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" class="py-2">
              <label for="" class="color_txt">No Telepon</label>
              <div class="pa-2 color_txt form_txt" v-if="field != 5">
                {{ person.telpon ? person.telpon : "-" }}
              </div>
              <v-text-field
                v-if="change && field == 5"
                hide-details
                @keyup.enter="updateData(5)"
                type="number"
                v-model="person.telpon"
                :loading="loading && field == 5"
                ref="field5"
                dense
                class="mt-3"
                color="#00b4cc"
              ></v-text-field>
              <div class="d-flex justify-end action_btn">
                <v-btn icon v-if="field != 5" small @click="switchElmt(5)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn icon v-if="change && field == 5" small @click="updateData"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" class="py-2">
                <label for="" class="color_txt">Kepentingan</label>
                <div class="pa-2 color_txt form_txt" v-if="field != 8">
                  {{ person.kepentingan ? person.kepentingan : "-" }}
                </div>
                <v-text-field
                  v-if="change && field == 8"
                  hide-details
                  @keyup.enter="updateData(8)"
                  v-model="person.kepentingan"
                  :loading="loading && field == 8"
                  ref="field8"
                  dense
                  class="mt-3"
                  color="#00b4cc"
                ></v-text-field>
                <div class="d-flex justify-end action_btn">
                  <v-btn icon v-if="field != 8" small @click="switchElmt(8)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon v-if="change && field == 8" small @click="updateData"
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </div>
              </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <v-row>
            <v-col class="py-2" cols="12">
              <label for="" class="color_txt">Jabatan</label>
              <div class="pa-2 color_txt form_txt" v-if="field != 2">
                {{ person.jabatan ? person.jabatan : "-" }}
              </div>
              <v-text-field
                v-if="change && field == 2"
                :loading="loading && field == 2"
                @keyup.enter="updateData(2)"
                hide-details
                v-model="person.jabatan"
                ref="field2"
                dense
                class="mt-3"
                color="#00b4cc"
              ></v-text-field>
              <div class="d-flex justify-end action_btn">
                <v-btn icon v-if="field != 2" small @click="switchElmt(2)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn icon v-if="change && field == 2" small @click="updateData"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col cols="12" class="py-2">
              <label for="" class="color_txt">Nip</label>
              <div class="pa-2 color_txt form_txt" v-if="field != 4">
                {{ person.nip ? person.nip : "-" }}
              </div>
              <v-text-field
                v-if="change && field == 4"
                hide-details
                :loading="loading && field == 4"
                @keyup.enter="updateData(4)"
                v-model="person.nip"
                ref="field4"
                dense
                class="mt-3"
                color="#00b4cc"
              ></v-text-field>
              <div class="d-flex justify-end action_btn">
                <v-btn icon v-if="field != 4" small @click="switchElmt(4)"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
                <v-btn icon v-if="change && field == 4" small @click="updateData"
                  ><v-icon>mdi-check</v-icon></v-btn
                >
              </div>
            </v-col>
            <v-col class="py-2" cols="12">
                <label for="" class="color_txt">Penanggung Jawab</label>
                <div class="pa-2 color_txt form_txt" v-if="field != 7">
                  {{ person.penanggung_jawab ? person.penanggung_jawab : "-" }}
                </div>
                <v-text-field
                  v-if="change && field == 7"
                  :loading="loading && field == 7"
                  @keyup.enter="updateData(7)"
                  hide-details
                  v-model="person.penanggung_jawab"
                  ref="field7"
                  dense
                  class="mt-3"
                  color="#00b4cc"
                ></v-text-field>
                <div class="d-flex justify-end action_btn">
                  <v-btn icon v-if="field != 7" small @click="switchElmt(7)"
                    ><v-icon>mdi-pencil</v-icon></v-btn
                  >
                  <v-btn icon v-if="change && field == 7" small @click="updateData"
                    ><v-icon>mdi-check</v-icon></v-btn
                  >
                </div>
              </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="mt-5">
        <div class="line_blue"></div>
        <div class="temp_tx">
          <b class="color_default"> Informasi Akun</b>
        </div>
      </div>
      <v-row>
        <v-col cols="6">
          <label for="" class="color_txt">Jenis Akun</label>
          <div class="role pa-2 color_txt">Eksternal</div>
        </v-col>
        <v-col cols="6">
          <v-col cols="12" class="py-2">
            <label for="" class="color_txt">Email</label>
            <div class="pa-2 color_txt form_txt" v-if="field != 6">
              {{ person.emails ? person.emails : "-" }}
            </div>
            <v-text-field
              v-if="change && field == 6"
              @keyup.enter="updateData(6)"
              hide-details
              v-model="person.emails"
              :loading="loading && field == 6"
              ref="field6"
              dense
              class="mt-3"
              color="#00b4cc"
            ></v-text-field>
            <div class="d-flex justify-end action_btn">
              <v-btn icon v-if="field != 6" small @click="switchElmt(6)"
                ><v-icon>mdi-pencil</v-icon></v-btn
              >
              <v-btn icon v-if="change && field == 6" small @click="updateData"
                ><v-icon>mdi-check</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import Swal from 'sweetalert2';
  export default {
    name: "profileEksternal",
    props: ['person'],
    data() {
      return {
        field: 0,
        change: false,
        loading: false,
      };
    },
    methods: {
      switchElmt(idx) {
        this.field = idx;
        this.change = true;
        setTimeout(() => {
          this.$refs[`field${idx}`].focus();
        }, 100);
      },
      updateData() {
        this.loading = true;
        this.$store
          .dispatch("user/updateUser", this.person)
          .then((data) => {
            this.field = 0;
            this.change = false;
            this.loading = false;
            this.Swal("success", data.message);
            this.$emit("close");
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            this.Swal("error", err.message);
          });
      },
      Swal(type, msg) {
        Swal.fire({
          icon: type,
          title: msg,
          showConfirmButton: false,
          position: "top-end",
          timer: 3000,
          toast: true,
          customClass: {
            title: "family",
          },
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .head_content {
    height: 170px;
  }
  .strip {
    height: 40px;
  }
  .frame_img {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    /* background: #9fe3ec; */
    background: #9fe3eca7;
    margin-top: -100px;
  
    /* opacity: 0.9; */
  }
  .pp {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }
  .btn_cam {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #9fe3eca7;
    cursor: pointer;
  }
  .form_txt {
    border-bottom: 1px #f2f2f2 solid;
  }
  .action_btn {
    margin-top: -40px;
  }
  .role {
    background: #f2f2f2;
  }
  .small-txt {
    font-size: small !important;
  }
  .temp_tx {
    background: #fff;
    width: max-content;
    padding: 5px;
    margin-top: -18px;
  }
  .line_blue {
    width: 100%;
    height: 2px;
    background: #00b4cc;
  }
  </style>