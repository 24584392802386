<template>
  <v-row justify="start">
    <v-dialog v-model="dialogChangePwd" persistent max-width="430px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="min-height: 300px" v-if="!forgot">
          <div class="pa-3" v-if="step === 1">
            <div class="px-5">
              <b>Ubah Password</b>
              <div class="mt-5">
                <b>Masukan Password Lama </b>
                <v-text-field
                  hide-details
                  v-model="old_password"
                  dense
                  class="mt-3"
                  color="#00b4cc"
                ></v-text-field>
                <div class="d-flex justify-end" @click="forgot = true">
                  <v-btn text class="text-capitalize" small
                    >Lupa Password?</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="pa-3" v-if="step === 2">
            <div class="px-5">
              <b>Ubah Password</b>
              <div class="mt-5">
                <b>Masukan Password Baru </b>
                <v-text-field
                  hide-details
                  v-model="new_password"
                  dense
                  class="mt-3"
                  color="#00b4cc"
                ></v-text-field>
              </div>
              <div class="mt-5">
                <b>Konfirmasi Password </b>
                <v-text-field
                  hide-details
                  v-model="re_password"
                  dense
                  class="mt-3"
                  color="#00b4cc"
                ></v-text-field>
              </div>
            </div>
          </div>
        </div>
        <div style="min-height: 300px" v-if="forgot">
          <div class="pa-3">
            <div class="px-5">
              <b>Lupa Password</b>
              <div class="mt-5">
                <!-- <b>Masukan Email Anda untuk reset password! </b>
                <v-text-field
                  hide-details
                  v-model="old_password"
                  dense
                  class="mt-3"
                  color="#00b4cc"
                ></v-text-field> -->
              </div>
            </div>
          </div>
        </div>
 
        <v-card-actions class="pa-3">
          <div
            v-if="!forgot"
            class="d-flex justify-space-between"
            style="width: 100%"
          >
          <v-btn
              outlined
              color="#00b4cc"
              v-if="step == 1"
              @click="$emit('walrus')"
              class="text-capitalize rounded-lg py-5"
              ><v-icon class="mr-1" small>mdi-arrow-left</v-icon
              ><b>Kembali</b></v-btn
            >

            <v-btn
              outlined
              color="#00b4cc"
              v-if="step == 2"
              @click="step = 1"
              class="text-capitalize rounded-lg py-5"
              ><v-icon class="mr-1" small>mdi-arrow-left</v-icon
              ><b>Kembali</b></v-btn
            >

            <v-btn
              color="#00b4cc"
              v-if="step !== 2"
              depressed
              dark
              class="text-capitalize py-5"
              @click="step = 2"
              ><b>Lanjut</b>
              <v-icon class="mr-1" small>mdi-arrow-right</v-icon></v-btn
            >
            <v-btn
              color="#00b4cc"
              depressed
              dark
              @click="updatePassword"
              class="text-capitalize py-5"
              v-if="step == 2"
              ><b>Simpan</b>
            </v-btn>
          </div>
          <div
            class="d-flex justify-space-between"
            v-if="forgot"
            style="width: 100%"
          >
            <v-btn
              outlined
              color="#00b4cc"
              @click="forgot = false"
              class="text-capitalize rounded-lg py-5"
              ><v-icon class="mr-1" small>mdi-arrow-left</v-icon
              ><b>Kembali</b></v-btn
            >
            <v-btn color="#00b4cc" depressed dark class="text-capitalize py-5" @click="resetPassword()"
              ><b>Reset</b>
            </v-btn>
          </div>
        </v-card-actions>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="#00b4cc"
          ></v-progress-circular>
          <b class="color_default">Loading...</b>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "dialogChangePassword",
  props: ["dialogChangePwd", "dataUser"],

  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
    }),
  },
  data() {
    return {
      step: 1,
      role: "",
      loading: false,
      old_password: "",
      new_password: "",
      re_password: "",
      forgot: false,
    };
  },
  methods: {
    resetPassword(){
      this.$store
        .dispatch("user/resetPassword", this.dataUser.id)
        .then((data) => {
          this.Swal("success", "Password berhasil direset");
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },

    updatePassword() {
      this.loading = true;
      let data = {
        password_lama: this.old_password,
        password_baru: this.new_password,
        password_ulang: this.re_password,
        id: this.dataUser.id,
      };
      this.$store
        .dispatch("user/updatePassword", data)
        .then((data) => {
          console.log(data);
          this.old_password = ''
          this.password_baru = ''
          this.password_lama = ''
          this.loading = false;
          this.Swal("success", "Password berhasil diubah");
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });
    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.side_left {
  width: 40%;
  height: 400px;
  border-radius: 0 !important;
}
.side_right {
  width: 60%;
}
.closer {
  width: 100%;
}
.temp_form {
  padding: 20px 25px;
}
.hide_overflow {
  overflow: hidden;
}
.family {
  font-family: Arial, Helvetica, sans-serif;
}
.small-txt {
  font-size: small !important;
}
</style>